import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: "/Account/login",
    method: "post",
    data: data,
  });
};


AuthService.changepassword = async function (data){
  const token = localStorage.getItem(AUTH_TOKEN);
  return await fetch({
    url:"/Account/changepassword",
    method:"post",
    data:data,
    });
};


AuthService.ClientRegistation = function (data){
return fetch({
  url:"/Account/Client-Registration",
  method:"post",
  data:data,
  });
};




AuthService.ClientEdit = function (data){
  
 //console.log("Authservice client Registration");
 //console.log(data);
return fetch({
  url:"/Account/Client-EditForm",
  method:"post",
  data:data,
  });
};







AuthService.forgotPassword = function (data){
  // alert("Authservice");
  ////console.log("Authservice");
  ////console.log(data);
return fetch({
  url:"/Account/forgot-password",
  method:"post",
  // contentType: "application/json",
  data:data,
  });
};

AuthService.resetPassword = function (data){
  
 //console.log("Authservice");
 //console.log(data);
return fetch({
  url:"/Account/reset-password",
  method:"post",
  data:data,
  });
};



AuthService.register = function (data) {
	data.Roles = ["Role1", "Role2"];
  // alert("AuthService");
  //
  return fetch({
    url: "/Account/register",
    method: "post",
    data: data,
  });
};

AuthService.logout = function () {
  return fetch({
    url: "/auth/logout",
    method: "post",
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: "/auth/loginInOAuth",
    method: "post",
  });
};

export default AuthService;
