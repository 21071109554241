import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
  AUTHENTICATED_ENTRY,
} from "configs/AppConfig";
import { GetNavigationConfig } from "configs/NavigationConfig";
import { useUserData } from "components/UserDataContext/UserDataContext";

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const userData = useUserData();
  const {factoryRoles, users} = userData;
  const navConfig = factoryRoles.length > 0 ? GetNavigationConfig(factoryRoles, users[0].email) : [];
  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  } 
  else if (location.pathname == "/") {
    let location_ = AUTHENTICATED_ENTRY;
    if (navConfig.some((x) => x.path == "/app/Home")) {
      location_ = "/app/Home";
    } else if (navConfig.some((x) => x.path == "/app/Dashboard")) {
      location_ = "/app/Dashboard";
    } 
    return <Navigate to={location_} replace />;
  }
  return <Outlet />;

};

export default ProtectedRoute;
